import React, { Component } from 'react';
import scriptLoader from 'react-async-script-loader';
import Recaptcha from 'react-recaptcha';
import Constants from '../Domain/constants'
// https://github.com/appleboy/react-recaptcha

let resetCaptcha = () => console.log('default');

class GoogleRecaptcha extends Component {
  UNSAFE_componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      if (isScriptLoadSucceed) {
        this._onScriptLoaded();
      } else {
        console.warn('fail to load script');
      }
    }
  }

  render() {
    let {
      captchaRef = (ref) => {},
      extraStyle = {},
      theme = 'light', // light | dark
      verifyCallback = (response) => console.log('verified!', response),
      expiredCallback = () => console.log('expired!'),
    } = this.props;

    if (!Constants.googleRecaptchaKey) {
        return (
            <div style={{color: 'tomato'}}> RECAPTCHA CLIENT KEY IS REQUIRED!!</div>
        )
    }
    return (
      <div style={{ ...extraStyle }}>
        <Recaptcha
          ref={e => {
            this.recaptchaInstance = e;
            captchaRef(e)
          }}
          theme={theme}
          sitekey={Constants.googleRecaptchaKey}
          render="explicit"
          verifyCallback={verifyCallback}
          expiredCallback={expiredCallback}
          onloadCallback={this._onScriptLoaded}
        />
      </div>
    );
  }

  _resetCaptcha = () => {
    this.recaptchaInstance.reset();
  };

  _onScriptLoaded = () => {
    console.log('recaptcha loaded');
    resetCaptcha = this._resetCaptcha;
  };
}

export default scriptLoader(
  'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit'
)(GoogleRecaptcha);
