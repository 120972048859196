import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { withPage } from '../Page';
import ActionCreator from '../ActionCreator';
import * as Widget from '../Components/Widget';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as Unstated from '../Utils/Unstated';
import * as L from '../Utils/Lang';
import Banner from '../Components/Banner';
import * as Icon from '../Components/Icon';
import Recaptcha from '../Components/GoogleRecaptcha';

class ContactPage extends React.Component {
  state = {
    success: false,
    verified: false,
  };

  render() {
    return (
      <Wrapper>
        <Banner
          image={PathPrefix('/images/pages/contact/contact_banner.png')}
          text={L.s('contact-us')}
        />

        <Content>
          <ContactInfoWrapper>
            <Widget.Row
              justify="space-around"
              extraCss="margin-bottom: 20px; flex-wrap: wrap;"
            >
              <InfoData>
                <Icon.Call color="#3b2946" style={{ margin: 10, width: 30, height: 30 }} />
                <div>
                  <a href="tel: +886-917-640-369" style={{ color: "#5d5d5d" }}>+886-917-640-369</a>
                </div>
                <div>
                  <a href="tel: +886-965-327-197" style={{ color: "#5d5d5d" }}>+886-965-327-197</a>
                </div>
                <div>
                  <a href="tel: 02-2368-7916" style={{ color: "#5d5d5d" }}>02-2368-7916</a>
                </div>
              </InfoData>
              <InfoData>
                <div style={{ marginBottom: 40}}>
                  <Icon.Print color="#3b2946" style={{ margin: 10, width: 30, height: 30 }} />
                  <div>{`02-2368-7906`}</div>
                </div>
                <div>
                  <Icon.Email color="#3b2946" style={{ margin: 10, width: 30, height: 30 }} />
                  <div>{`chig20170720@gmail.com`}</div>
                </div>
              </InfoData>
              <InfoData>
                <Icon.Location color="#3b2946" style={{ margin: 10, width: 30, height: 30 }} />
                <div>{L.s('address-data')}</div>
              </InfoData>
            </Widget.Row>
          </ContactInfoWrapper>

          <ChargingMethodWrapper>
            <Header>
              <h2>{L.s('charging-method-title')}</h2>
              <p>{L.s('charging-method-content')}</p>
            </Header>
          </ChargingMethodWrapper>

          <Header>
            <h2>{L.s('online-contact-form-title')}</h2>
            <p>{L.s('online-contact-form-subtitle')}</p>
          </Header>

          <Unstated.Form
            init={() => ({
              values: {
                name: '',
                phone: '',
                email: '',
                region: '',
                consult_type: '',
                description: '',
              },
              editing: false,
            })}
            submit={this._formSubmit}
            validate={this._formValidator}
          >
            {({ values, errors, editProps, inputProps, submitProps }) => {
              return (
                <Widget.MaxWidth width={800} extraCss="padding: 10px;">
                  <Widget.Col>
                    <Label>{L.s(`name`)}</Label>
                    <Widget.Input
                      {...inputProps.name}
                      extraCss={InputExtraCss}
                    />
                  </Widget.Col>
                  <Widget.Col>
                    <Label>{L.s(`phone`)}</Label>
                    <Widget.Input
                      {...inputProps.phone}
                      extraCss={InputExtraCss}
                    />
                  </Widget.Col>
                  <Widget.Col>
                    <Label>{L.s(`email`)}</Label>
                    <Widget.Input
                      {...inputProps.email}
                      extraCss={InputExtraCss}
                    />
                  </Widget.Col>
                  <Widget.Col>
                    <Label>{L.s(`region`)}</Label>
                    <Widget.Input
                      {...inputProps.region}
                      extraCss={InputExtraCss}
                    />
                  </Widget.Col>
                  <Widget.Col>
                    <Label>{L.s(`consult-type`)}</Label>
                    <Widget.MuiSelect
                      {...inputProps.consult_type}
                      underlineStyle={{ marginLeft: 0 }}
                      labelStyle={{ color: '#444' }}
                      selectedMenuItemStyle={{
                        color: '#8f54b3',
                        backgroundColor: 'rgba(143, 84, 179, 0.2)',
                      }}
                      menuItemStyle={{ color: '#444' }}
                    >
                      <Widget.MenuItem
                        key={'house'}
                        value={'house'}
                        primaryText={L.s(`consult-type-house`)}
                      />
                      <Widget.MenuItem
                        key={'office'}
                        value={'office'}
                        primaryText={L.s(`consult-type-office`)}
                      />
                      <Widget.MenuItem
                        key={'building'}
                        value={'building'}
                        primaryText={L.s(`consult-type-building`)}
                      />
                      <Widget.MenuItem
                        key={'decorate'}
                        value={'decorate'}
                        primaryText={L.s(`consult-type-decorate`)}
                      />
                      <Widget.MenuItem
                          key={'brand'}
                          value={'brand'}
                          primaryText={L.s(`consult-type-brand`)}
                      />
                      <Widget.MenuItem
                        key={'others'}
                        value={'others'}
                        primaryText={L.s(`consult-type-others`)}
                      />
                    </Widget.MuiSelect>
                  </Widget.Col>
                  <Widget.Col>
                    <Label>{L.s('contact-text')}</Label>
                    <Widget.TextArea
                      {...inputProps.description}
                      extraCss={InputExtraCss + 'height: 150px;'}
                    />
                  </Widget.Col>

                  <Widget.Center extraCss="margin-top: 20px;">
                    <Recaptcha
                      captchaRef={(ref) => this.captchaRef = ref}
                      verifyCallback={(response) => {
                        this.setState({ verified: response });
                        console.log('response', response);
                      }}
                      extraStyle={{ marginBottom: 10 }}
                    />
                    <Widget.Button
                      label={L.s('submit')}
                      onClick={() => submitProps.submit()}
                      hover
                      labelStyle={{ color: '#3b2946' }}
                    />
                    {submitProps.submitting && (
                      <Widget.Center>
                        <Widget.Spinner />
                      </Widget.Center>
                    )}

                    {submitProps.submitError && (
                      <FormResultText>
                        {L.s(`${submitProps.submitError}`)}
                      </FormResultText>
                    )}

                    {this.state.success && (
                      <FormResultText success>
                        {L.s('contact-form-send-success')}
                      </FormResultText>
                    )}
                  </Widget.Center>
                </Widget.MaxWidth>
              );
            }}
          </Unstated.Form>
        </Content>
      </Wrapper>
    );
  }

   _formSubmit = async (values) => {
    let { appActions } = this.props;
    let { verified } = this.state;
    let { name, phone, email, region, consult_type, description } = values;
    this.setState({ success: '', error: '' });
    if (!name || !phone || !email || !region || !consult_type || !description) {
      return Promise.resolve({ error: 'field-required' });
    }
    if (!verified) {
      return Promise.resolve({ error: 'please-check-recaptcha' });
    }


    return appActions.postContactForm({
            name,
            phone,
            email,
            region,
            consult_type,
            description,
            captcha_response: verified,
        })
        .then(result => {
            this.setState({ success: 'contact-form-send-success', verified: false });
            this.captchaRef.reset();
            return Promise.resolve({
                values: {
                    name: '',
                    phone: '',
                    email: '',
                    region: '',
                    consult_type: '',
                    description: '',
                },
            });
        })
        .catch(err => {
            this.setState({ success: false });
            if (err.status === 403) {
                return Promise.resolve({error: 'submit-form-recaptcha-error'})
            }
            return Promise.resolve({ error: 'submit-contact-form-error' });
        });
  };
}

const Wrapper = styled.div``;
const Label = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;

  &::before {
    content: '*';
    margin-right: 5px;
    color: tomato;
  }
`;
const FormResultText = styled.div`
  color: ${props => (props.success ? '#00c300' : 'tomato')};
  text-align: center;
`;

const Content = styled.div``;

const Header = styled.div`
  color: #444;
  text-align: center;
  padding: 20px;

  & > p {
    font-size: 16px;
    white-space: pre-wrap;
  }
  & > h2 {
    font-size: 26px;
    font-weight: 400;
  }
`;

const InfoData = styled.div`
  min-width: 25vw;
  display: flex;
  color: #5d5d5d;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  & > div {
    max-width: 200px;
    font-weight: 500;
  }
  ${props => props.extraCss};
`;

const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
`;
const InputExtraCss = css`
`;

const ChargingMethodWrapper = styled.div`
  padding: 10px;
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(ContactPage)
);
